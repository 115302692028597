import "twin.macro"
import React, { FC } from "react"
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import Button, { ButtonLink } from "./Button"

type props = { content: string; name: string; image: any; slug: string }

const BlogCard: FC<props> = ({ content, name, image, slug }) => {
  const img = getImage(image) as IGatsbyImageData
  return (
    <div tw="w-full  max-w-lg h-full">
      <div tw="relative ml-0 mr-0 sm:mr-10 w-full h-full">
        <span tw="absolute top-0 left-0 w-full h-full mt-1 ml-1 bg-yellow-300 rounded-lg"></span>
        <div tw="relative h-full p-5 space-y-3 bg-white border-2 border-yellow-300 rounded-lg">
          <GatsbyImage
            tw="rounded-md h-auto w-full"
            image={img}
            alt="profile"
          />
          <div tw="flex items-center -mt-1">
            <h3 tw="text-xl font-medium  text-gray-900">{name}</h3>
            {/* <p tw=" text-gray-900">{profile}</p> */}
          </div>

          <p tw="mb-2 text-gray-600">{content}</p>
          <div tw="flex justify-end ">
            <ButtonLink to={slug}>Read More</ButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
